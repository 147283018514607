<template>
  <Connect @getContract="getContract"/>
  <div class="index">
    <div class="bg">
      <div class="navBar">
        <img src="@/assets/imgs/icon_arrowL.png" @click="router.go(-1)">
        <div class="wallet">
          <div class="address">{{ short_address }}</div>
          <img src="@/assets/imgs/new_wallet.png" alt="">
        </div>
        <view class="title">Home</view>
      </div>
      <div class="h60"></div>
      <!--有NFT-->
      <div v-if="nftList.length" class="banner">
        <div class="list">
          <div v-for="(item, index) in nftList" :key="index" class="item" @click="router.push('/product?id=' + item.nft_id)">
            <div class="name">{{ item.name }}</div>
            <div class="price">${{ item.price }}</div>
            <div class="new">{{ item.type }}</div>
            <div class="balance">
              <img src="@/assets/imgs/new_star.png">
              <div class="text">Balance: {{ item.balance }}</div>
            </div>
            <img class="nft" :src="item.imgUrl">
          </div>
        </div>
      </div>
      <!--无NFT-->
      <div v-else class="noNft">
        <div class="text">No NFT Found</div>
        <div class="wrap">
          <img class="noBg" src="@/assets/imgs/new_noNft1.png" alt="">
          <img class="img" src="@/assets/imgs/new_noNft2.png" alt="">
        </div>
      </div>
    </div>
    <div class="business">
      <div class="title">Ecosystem Businesses</div>
      <div class="list">
        <div v-for="(item,index) in menuList" :key="index" class="item" @click="handleClick(item.url)">
          <img :src="item.images" alt="">
          <div class="text">{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>
  <TabBar/>

  <!--银行卡弹框-->
  <van-popup v-model:show="show" class="cardPop">
    <div class="cardWrap">
      <div class="item" @click="router.push('/card')">
        <img src="@/assets/imgs/icon_add.png" alt="">
        <div class="text">ACTIVATE NEW CARD</div>
      </div>
      <a href="https://www.metagenesis-card.com" class="item">
        <img src="@/assets/imgs/icon_global.png" alt="">
        <div class="text">ENTER META GENESIS</div>
      </a>
      <div class="back" @click="show = false">&lt; Back</div>
    </div>
  </van-popup>

  <!--即将开放弹框-->
  <van-popup v-model:show="cShow" class="comingPop">
    <div class="comingWrap">
      <div class="text">COMING SOON</div>
      <div class="back" @click="cShow = false">&lt; Back</div>
      <img src="@/assets/imgs/coming.png" alt="">
    </div>
  </van-popup>
</template>

<script setup>
  import Connect from '@/components/Connect'
  import TabBar from "@/components/TabBar";
  import { ref } from 'vue';
  import { useRouter } from 'vue-router'
  import { register, getMenus } from '@/request/api'
  import { showLoadingToast, closeToast } from 'vant'

  const router = useRouter()
  const show = ref(false)
  const cShow = ref(false)

  showLoadingToast({
    message: 'Loading...',
    forbidClick: true,
    duration: 0
  })

  let address = ''
  const short_address = ref('')
  let blockNftContract = null
  const nftList = ref([])
  async function getContract(obj) {
    address = obj.address
    short_address.value = obj.address.substr(0, 4) + '...' + obj.address.substring(obj.address.length - 3)
    register({ address }).then(res => {})
    blockNftContract = obj.blockNftContract
    const nftArr = [
      { id: 22, name: 'META BASE', price: 50, type: 'VOUCHER', imgUrl: require('@/assets/imgs/nft11.png') },
      { id: 30, name: 'RAMS EXPLORER', price: 100, type: 'VOUCHER', imgUrl: require('@/assets/imgs/nft1.png') },
      { id: 28, name: 'Play 2 Earn', price: 100, type: 'VOUCHER', imgUrl: require('@/assets/imgs/nft14.png') },
      { id: 23, name: 'META NET', price: 100, type: 'VOUCHER', imgUrl: require('@/assets/imgs/nft12.png') },
      { id: 11, name: 'EXPLORER', price: 100, type: 'NEW NFT', imgUrl: require('@/assets/imgs/nft1.png') },
      { id: 18, name: 'DIGITAL BANK ACC', price: 100, type: 'NEW NFT', imgUrl: require('@/assets/imgs/nft8.png') },
      { id: 26, name: 'META CRYPTO', price: 200, type: 'VOUCHER', imgUrl: require('@/assets/imgs/nft13.png') },
      { id: 24, name: 'MY WORLD FX', price: 200, type: 'BASIC NFT', imgUrl: require('@/assets/imgs/nft9.png') },
      { id: 27, name: 'MY WORLD FX', price: 200, type: 'TESTING NFT', imgUrl: require('@/assets/imgs/nft9.png') },
      { id: 29, name: 'RAMS DISCOVERER', price: 300, type: 'VOUCHER', imgUrl: require('@/assets/imgs/nft15.png') },
      { id: 12, name: 'ADVENTURER', price: 500, type: 'NEW NFT', imgUrl: require('@/assets/imgs/nft2.png') },
      { id: 25, name: 'MY WORLD FX', price: 600, type:'PREMIUM NFT', imgUrl: require('@/assets/imgs/nft10.png') },
      { id: 13, name: 'INFLUENCER', price: 1000, type: 'NEW NFT', imgUrl: require('@/assets/imgs/nft3.png') },
      { id: 14, name: 'PIONEER', price: 3000, type: 'NEW NFT', imgUrl: require('@/assets/imgs/nft4.png') },
      { id: 15, name: 'NAVIGATOR', price: 5000, type: 'NEW NFT', imgUrl: require('@/assets/imgs/nft5.png') },
      { id: 16, name: 'MASTER', price: 10000, type: 'NEW NFT', imgUrl: require('@/assets/imgs/nft6.png') },
      { id: 17, name: 'LEGEND', price: 20000, type: 'NEW NFT', imgUrl: require('@/assets/imgs/nft7.png') }
    ]
    // address = '0xF0c53D34f14B8c0AddEfBeedFBF4c6Ba829B9882'
    // address = '0xcd0728526a9cf0658ca311f8e7e955ee2e7e91ce'
    for (let i = 0; i <= nftArr.length - 1; i++) {
      const nftBanner = parseInt(await blockNftContract.balanceOf(address, nftArr[i].id))
      if(nftBanner > 0) {
        nftList.value.push({
          nft_id: nftArr[i].id,
          name: nftArr[i].name,
          price: nftArr[i].price,
          type: nftArr[i].type,
          balance: nftBanner,
          imgUrl: nftArr[i].imgUrl
        })
      }
    }
    closeToast()
  }

  const menuList = ref([])
  getMenus().then(res => {
    if(res.success) {
      menuList.value = res.data
    }
  })

  function handleClick(url) {
    if(url == 2) {
      show.value = true
    } else if(url == 1) {
      cShow.value = true
    } else {
      window.location.href = url
    }
  }
</script>

<style lang="less" scoped>
.index{
  .bg{
    background: #06C8A1;
    border-radius: 0 0 16px 16px;

    .navBar{
      width: 100%;
      background: #06C8A1;
      height: 60px;
      line-height: 44px;
      font-weight: 500;
      top: 0;
      left: 0;
      text-align: center;
      color: #000000;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 12px;
      box-sizing: border-box;
      position: fixed;

      >img{
        width: 24px;
        height: 24px;
      }
      .wallet{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 10px 5px 16px 0;

        .address{
          color: #000000;
          font-size: 12px;
          height: 18px;
          line-height: 18px;
          background-color: #ffffff;
          border: 1px solid #52A497;
          border-radius: 14px;
          text-align: center;
          margin-right: 5px;
          padding: 0 6px;
        }
        img{
          width: 20px;
          height: 20px;
        }
      }
      .title{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .h60{
      height: 60px;
    }
  }
  .banner{
    padding: 0 0 20px 16px;
    overflow: hidden;
    overflow-x: auto;

    .list{
      display: flex;

      .item{
        width: 302px;
        height: 142px;
        background: url("~@/assets/imgs/new_nftBg.png") no-repeat;
        background-size: 100% 100%;
        padding: 16px 24px;
        box-sizing: border-box;
        position: relative;
        margin-right: 16px;
        flex-shrink: 0;

        .name{
          font-size: 16px;
          line-height: 26px;
          font-weight: 500;
          color: #000000;
        }
        .price{
          color: #06C8A1;
          font-weight: 500;
          font-size: 24px;
          line-height: 28px;
          margin-bottom: 4px;
        }
        .new{
          color: #697377;
          font-size: 12px;
          line-height: 16px;
          margin-bottom: 12px;
        }
        .balance{
          display: flex;
          align-items: center;

          img{
            width: 24px;
          }
          .text{
            color: #697377;
            font-size: 14px;
            margin-left: 6px;
            line-height: 24px;
          }
        }
        .nft{
          width: 150px;
          position: absolute;
          right: 9px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
  .noNft{
    padding-bottom: 10px;

    .text{
      font-size: 24px;
      font-weight: 500;
      text-align: center;
    }
    .wrap{
      position: relative;

      .noBg{
        width: 293px;
        margin: 0 auto;
      }
      .img{
        width: 175px;
        position: absolute;
        left: 92px;
        top: -5px;
      }
    }
  }
  .business{
    padding-left: 16px;

    .title{
      font-weight: 500;
      font-size: 18px;
      padding-top: 24px;
      margin-bottom: 30px;
    }
    .list{
      padding: 0 16px 0 15px;
      display: flex;
      flex-wrap: wrap;

      .item{
        margin: 0 24px 20px 0;

        img{
          width: 60px;
          height: 60px;
          border-radius: 20px;
          background: #06C8A1;
          margin-bottom: 10px;
        }
        .text{
          font-size: 14px;
          color: #697377;
          text-align: center;
        }
      }
      .item:nth-child(4n+4){
        margin-right: 0;
      }
      .item:hover{
        .image{
          background: #33F88F;
        }
      }
    }
  }
}
.cardWrap{
  .item{
    height: 48px;
    border: 2px solid #06C8A1;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 26px 0 12px;
    margin-bottom: 20px;

    img{
      width: 30px;
    }
    .text{
      font-size: 16px;
      font-weight: 500;
    }
  }
  a.item{
    color: #fff;
  }
  .item:hover{
    background-color: #06C8A1;
  }
}
.back{
  color: #697377;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  line-height: 26px;
}
.back:hover{
  color: #33F88F;
}
.comingWrap{
  height: 102px;
  background-color: #000000;
  box-sizing: border-box;
  border: 5px solid #06C8A1;
  border-radius: 12px;
  text-align: center;
  position: relative;

  .text{
    color: #F8F8F8;
    font-size: 25px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 2.25px;
    text-transform: capitalize;
    font-family: 'DM Sans Bold';
    margin: 24px 0 4px 0;
  }
  .back{
    position: relative;
    z-index: 1;
  }
  img{
    width: 245px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -146px;
  }
}
</style>
